<template>
  <div class="bankCard">
    <div class="inputcont">
      <div class="setinfo">
        <div class="item">
          <div class="tit">真实姓名<span>*</span></div>
          <div class="inp">
            <input type="text" v-model="subData.name" placeholder="请输入银行卡对应开户人" />
          </div>
        </div>
        <div class="item">
          <div class="tit">银行卡号<span>*</span></div>
          <div class="inp">
            <input type="text" v-model="subData.bank_no" placeholder="请输入银行卡号" />
          </div>
        </div>

        <div class="item">
          <div class="tit">开户行<span>*</span></div>
          <div class="inp" @click="bankShowClick">
            {{ subData.open_bank_text ?? "请选择开户行" }}
          </div>
          <div class="aftertext"><van-icon name="arrow" /></div>
        </div>

        <div class="item">
          <div class="tit">账户类型<span>*</span></div>
          <div class="inp" @click="typeShowClick">
            {{ subData.account_type_text ?? "请选择账户类型" }}
          </div>
          <div class="aftertext"><van-icon name="arrow" /></div>
        </div>
        <div class="item">
          <div class="tit">支行名称</div>
          <div class="inp">
            <input type="text" v-model="subData.bank_branch" placeholder="请输入支行名称" />
          </div>
        </div>
        <div class="item" v-show="false">
          <div class="tit">手机号<span>*</span></div>
          <div class="inp">
            <input type="text" v-model="subData.mobile" placeholder="请输入手机号码" />
          </div>

          <button type="button" class="sendbtn" v-if="isSendcode" @click="sendCodeClick">发送验证码</button>
          <button type="button" class="waitbtn" v-else>{{ timer }}秒后可重发</button>

        </div>

        <div class="item" v-if="showVeryCode">
          <div class="tit">输入验证码</div>
          <div class="inp">
            <input type="text" v-model="subData.mobile_code" placeholder="请输入验证码" />
          </div>
        </div>

      </div>

      <div class="piclist">
        <div class="item">
          <div class="text">
            <div class="top">
              <div class="title">银行卡正面图片<span>*</span></div>
            </div>
            <div class="tip">图片大小不超过20M</div>
          </div>
          <div class="pic">
            <van-uploader v-model="cardPIC" :max-size="20 * 1024 * 1024" :max-count="1" :after-read="afterRead" />
          </div>
        </div>
      </div>

      <div class="remark">
        <div class="tit">备注</div>
        <div class="textarea">
          <textarea rows="5" v-model="subData.remark"></textarea>
        </div>
      </div>
    </div>
    <div class="btns">
      <button type="button" @click="backClick">返回</button>
      <button type="button" @click="submitClick">提交</button>
    </div>

    <!-- 开户行弹窗 -->
    <van-popup v-model:show="bankSelectShow" position="bottom" round>
      <van-picker title="选择开户行" :columns="bankList"  :default-index="defaultBank" @confirm="bankOnConfirm" @cancel="bankOnCancel"
        :columns-field-names="selectFieldName" />
    </van-popup>
    <!-- 账户类型弹窗 -->
    <van-popup v-model:show="typeSelectShow" position="bottom" round>
      <van-picker title="选择账户类型" :columns="typeList"  :default-index="defaultType" @confirm="typeOnConfirm" @cancel="typeOnCancel"
                  :columns-field-names="selectFieldName" />
    </van-popup>

    <!-- 旧手机验证码弹窗 -->
    <van-popup v-model:show="telVerifyCodeshow" position="center" :style="{ width: '80%' }" round>
      <div class="verifycodebox">
        <div class="title">修改验证</div>
        <div class="tip">您修改银行卡信息需进行手机号码安全验证</div>
        <div class="tele">账户手机号:{{subData.old_mobile}}</div>
        <div class="code">
          <div class="tit">验证码：</div>
          <div class="inp">
            <input type="text" v-model="subData.old_mobile_code" :disabled="disableSwitch" placeholder="请输入验证码" />
          </div>

          <button type="button" class="sendbtn" v-if="isTcSendcode" @click="sendTcCodeClick">发送验证码</button>
          <button type="button" class="waitbtn" v-else>{{ tcTimer }}秒后可重发</button>
        </div>

        <div class="btn">
          <button type="button" @click="tcCancer">取消</button>
          <button type="button" @click="tcSubmit">提交</button>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script setup>
import {ref, onMounted, reactive} from "vue";
import API from "@/axios";
import mitts from "@/bus";
import {useRoute, useRouter} from "vue-router/dist/vue-router";
import {Toast} from "vant";
const route = useRoute();
const router = useRouter();
const subData = reactive({
  id: '',
  name: '',
  bank_no: '',
  open_bank:'',
  open_bank_text:'',
  account_type:'',
  account_type_text:'',
  bank_img:'',
  bank_branch: '',
  mobile: '',
  mobile_code: '',
  old_mobile:'',
  old_mobile_code:'',
  remark: '',
  bank_mobie: '' // 银行卡绑定的手机号
});
const cardId = ref(0);
const cardPIC = ref([])// 图片预览数组
const bankList = ref([]);
const defaultBank = ref('')
const typeList = ref([]);
const defaultType = ref('')
const selectFieldName = {
  text: 'title',
  value: 'val',
};

onMounted(() => {
  if (route.query.id) {
    subData.id = cardId.value = route.query.id;
  }

  getInfo();

  setTimeout(() => {
    mitts.emit("toptitle", false);
    mitts.emit("closeLoading");
  }, 500);
});

function getInfo() {
  API({
    url: "/api/bankCardPage",
    method: "post",
    data: {
      id: cardId.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      //编辑初始信息
      let resInfo = res.data.data.info;
      if(resInfo){
        for (let i in subData) {
          subData[i] = resInfo[i]
        }

        //银行卡正面图片
        if(resInfo.bank_img){
          cardPIC.value.push({
            url:resInfo.bank_img
          })
        }
      }
      //配置
      let resConf =res.data.data.conf;
      //开户行配置
      bankList.value = resConf.open_bank;
      resConf.open_bank.forEach((item,index) => {
        if(item.checked == 1){
          subData.open_bank_text = item.title
          defaultBank.value =index
        }
      });
      subData.bank_mobie = subData.mobile

      //账户配置
      typeList.value = resConf.account_type;
      typeList.value.forEach((item,index) => {
        if(item.checked == 1){
          subData.account_type = item.val
          subData.account_type_text = item.title
          defaultType.value = index
        }
      });

      //总线传参赋值底部菜单
      mitts.emit('navs', res.data.data.main_nav);
      mitts.emit("toptitle", false);
      mitts.emit('navindex', 'bills');
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function afterRead(file) {
  file.status = "uploading";
  file.message = "上传中";
  let form = new FormData();
  form.append("file", file.file);
  API({
    url: "/web/uploadImg",
    method: "post",
    data: form,
    timeout: 60000,
  }).then((res) => {
    file.url = res.data.url;
    file.status = "done";
    subData.bank_img = file.url;
  });
}

// 选择开户行
const bankSelectShow = ref(false)
function bankShowClick() {
  bankSelectShow.value = true
}
function bankOnConfirm(value) {
  subData.open_bank_text = value.title
  subData.open_bank = value.val
  bankSelectShow.value = false
}
function bankOnCancel() {
  bankSelectShow.value = false
}

// 选择账户类型
const typeSelectShow = ref(false)
function typeShowClick() {
  typeSelectShow.value = true
}
function typeOnConfirm(value) {
  subData.account_type_text = value.title
  subData.account_type = value.val
  typeSelectShow.value = false
}
function typeOnCancel() {
  typeSelectShow.value = false
}

//界面返回按钮
function backClick() {
  router.push({
    path: "/event/event",
    query:{url:encodeURIComponent('/web/bankList')}
  })
}
//数据提交
function submitClick() {
  if(subData.mobile && (subData.mobile != subData.bank_mobie) && !subData.mobile_code){
    Toast.fail('请先发送并填写验证码');
    return;
  }
  if(subData.old_mobile){
    telVerifyCodeshow.value = true
    return;
  }

  API({
    url: "api/bankSubmit",
    method: "post",
    data: subData
  }).then((res) => {
    if (res.data.status == 200) {
      router.push({
        path: "/event/event",
        query:{url:encodeURIComponent(res.data.data.url)}
      })
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function tcCancer(){
  telVerifyCodeshow.value = false
}
//短信弹窗提交
function tcSubmit(){
  if(!subData.old_mobile_code){
    Toast.fail('请先填写验证码');
    return;
  }
  API({
    url: "api/bankSubmit",
    method: "post",
    data: subData
  }).then((res) => {
    if (res.data.status == 200) {
      router.push({
        path: "/event/event",
        query:{url:encodeURIComponent(res.data.data.url)}
      })
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

// 验证码倒计时
const isSendcode = ref(true)
const timer = ref(60)

const travTimer = ref(null)
const showVeryCode = ref(false)

function sendCodeClick() {
  if(!subData.mobile){
    Toast.fail('请先填写手机号');
    return;
  }
  API({
    url: "web/sendSms",
    method: "post",
    data: {
      phone:subData.mobile,
      type:'bank_bind',
      source: 'mobile_verify'
    }
  }).then((res) => {
    if (res.data.status == 200) {
      isSendcode.value = false
      showVeryCode.value = true
      timer.value = 60
      travTimer.value = setInterval(() => {
        if (timer.value == 0) {
          isSendcode.value = true;
          clearInterval(travTimer.value)
        } else {
          timer.value = timer.value - 1
        }
      }, 1000)
    }else{
      Toast.fail(res.data.msg);
    }
  });
}

// 弹窗验证码倒计时
const telVerifyCodeshow = ref(false)
const isTcSendcode = ref(true)
const tcTimer = ref(60)
const TCtravTimer = ref(null)

const disableSwitch = ref(true)

function sendTcCodeClick() {
  if(!subData.old_mobile){
    Toast.fail('暂无手机号');
    return;
  }
  API({
    url: "web/sendSms",
    method: "post",
    data: {
      phone:subData.old_mobile,
      type:'bank_bind',
      source: 'mobile_verify'
    }
  }).then((res) => {
    if (res.data.status == 200) {
      tcTimer.value = 60
      isTcSendcode.value = false
      disableSwitch.value = false
      TCtravTimer.value = setInterval(() => {
        if (tcTimer.value == 0) {
          disableSwitch.value = true
          isTcSendcode.value = true;
          clearInterval(TCtravTimer.value)
        } else {
          tcTimer.value = tcTimer.value - 1
        }
      }, 1000)
    }else{
      Toast.fail(res.data.msg);
    }
  });
}



</script>

<style lang="less" scoped>
.bankCard {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.inputcont{
  height: 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.setinfo {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 30px;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    .tit {
      margin-right: 20px;

      span {
        color: #f00;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 26px;
        font-size: 30px;
      }
    }

    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      justify-content: flex-end;

      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .aftertext {
      font-weight: 600;
      color: #999;

      i {
        padding: 0;
      }

    }

    button {
      font-size: 24px;
      padding: 10px;
      border-radius: 10px;
      margin-left: 10px;
    }

    .sendbtn {
      color: #fff;
      background: @theme-secondary-color;
    }

    .waitbtn {
      color: #999;
      background: #f6f6f6;
    }
  }
}

.piclist {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f5f5f5;

    .text {
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .top {
        .title {
          margin-bottom: 10px;

          span {
            color: #f00;
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            line-height: 26px;
            font-size: 30px;
          }
        }

        .example {
          border-radius: 10px;
          padding: 5px;
          background: none;
          border: 1px solid @theme-secondary-color;
          font-size: 24px;
          color: @theme-secondary-color;
        }
      }

      .tip {
        font-size: 28px;
        color: #999;
      }
    }
  }

  .item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.remark {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #d7d7d7;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;

  .tit {
    margin-bottom: 20px;

    span {
      color: #f00;
    }
  }

  .textarea {
    width: 100%;

    textarea {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      box-sizing: border-box;
      resize: none;
      background: #f9f9f9;
    }
  }
}

.btns {
  display: flex;
  margin-bottom: 20px;

  button {
    background: #fff;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }

  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
  }
}

.verifycodebox {
  .title {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }

  .tip {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .tele {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .tit {
      margin-right: 20px;
    }

    .inp {
      min-width: 0;
      flex: 1;
      display: flex;
      justify-content: flex-end;

      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    button {
      font-size: 24px;
      padding: 10px;
      border-radius: 10px;
      margin-left: 10px;
    }

    .sendbtn {
      color: #fff;
      background: @theme-secondary-color;
    }

    .waitbtn {
      color: #999;
      background: #f6f6f6;
    }
  }

  .btn {
    display: flex;
    button {
      background: #fff;
      font-size: 28px;
      height: 66px;
      line-height: 66px;
      border-radius: 10px;
      margin-right: 20px;
      flex: 1;
      border: 3px solid @theme-secondary-color;
      color: @theme-secondary-color;
    }

    button:last-of-type {
      border: 3px solid @theme-secondary-color;
      background: @theme-secondary-color;
      color: @font-white-color;
      margin-right: 0;
    }
  }
}
</style>
